<template>
    <div class="alertWrapper">
        <template v-for="(alert, ix) in alerts">
            <!-- <v-snackbar :value="alert.value" bottom right :timeout="alert.timeout" :color="alert.color" :key="ix"
                @input="alert.value = false">
                {{ alert.text }}
                <template v-slot:action="{ attrs }">
                    <v-btn depressed icon @click="alert.value = false" v-bind="attrs">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar> -->
            <div :class="'alert px-3 py-2 mb-2 rounded white--text elevation-3 ' + alert.color" :key="ix">
                <v-row no-gutters>
                    <v-col align-self="center">{{ alert.text }}</v-col>
                    <v-col cols="1" class="text-right">
                        <v-btn icon small color="white" @click="remove(alert.id)"><v-icon small>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>                
            </div>
        </template>
    </div>
</template> 

<script>
import { serverBus } from '@/main';
export default {    
    data() {
        return {
            alerts: []
        }
    },
    mounted() {
        serverBus.$on( 'alert', (alert) => {
            this.createAlert(alert);
        });
    },
    methods: {
        createAlert(alert) {
            let id = this.createID();
            this.alerts.push({
                id,
                ...alert
            });

            setTimeout(() => { 
                this.remove(id);
            }, alert.timeout??6000);
        },
        remove(id) {
            let ix = this.alerts.findIndex(a => a.id == id);
            this.alerts.splice(ix, 1);
        },
        createID() {
            return '_' + Math.random().toString(36).substr(2, 9);
        }
    }
}
</script>

<style scoped>
.alertWrapper {
    position: fixed;
    bottom: 0;
    right: 10px;
    height: auto;
    z-index: 300;
}
.alertWrapper .alert {
    min-width: 300px;
    z-index: 300;
    position: relative;
}
.alertWrapper .v-snack {
    position: initial;
    margin: 10px 5px;
}
</style>