import { render, staticRenderFns } from "./Alerts.vue?vue&type=template&id=233f83c8&scoped=true&"
import script from "./Alerts.vue?vue&type=script&lang=js&"
export * from "./Alerts.vue?vue&type=script&lang=js&"
import style0 from "./Alerts.vue?vue&type=style&index=0&id=233f83c8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233f83c8",
  null
  
)

export default component.exports