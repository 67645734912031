import router from '@/router';
import { API } from '@/inc/api';
import { serverBus } from '@/main';
import { _st } from '@/softech';
import * as jwt from 'jose';
import store from '../index';

export const admin = {
    namespaced: true,
    state: {
        pageTitle       : 'Dashboard',
        orderToView     : null,
        userLongName    : '',
        userLastLogin   : '',
        userPrivileges  : {},
        tokenExp        : '',
    },
    mutations: {
        setPageTitle( state, payload ) {
            state.pageTitle = payload;
        },
        setOrderToView( state, payload ) {
            state.orderToView = payload;
        },
        setUserLongName( state, payload ) {
            state.userLongName = payload;
        },
        setUserLastLogin( state, payload ) {
            state.userLastLogin = payload;
        },
        setUserPrivileges( state, payload ) {
            state.userPrivileges = payload;
        },
        setTokenExp( state, payload ) {
            state.tokenExp = payload;
        },
        setAppState( state, payload ) {
            state.appState = payload;
        }
    },
    actions: {
        setPageTitle({ commit }, payload ) {
            commit('setPageTitle', payload);
        },
        setOrderToView({ commit }, payload ) {
            commit('setOrderToView', payload);
        },
        setUserLongName({ commit }, payload ) {
            commit('setUserLongName', payload);
        },
        setUserLastLogin({ commit }, payload ) {
            commit('setUserLastLogin', payload);
        },
        setUserPrivileges({ commit }, payload ) {
            commit('setUserPrivileges', payload);
        },
        async getUserPrivileges({ commit } ) {
            try {
                let api = new API();
                let res = await api.get('/admin/user-privileges');

                if( res.data.status !== true ) {
                    serverBus.$emit('alert', { 
                        text: res.data.message, 
                        color: res.data.error ? 'error' : 'warning', 
                        timeout: 6000 
                    });
                    return;
                }

                commit('setUserPrivileges', res.data.data);
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        setUserToken({ commit }, payload ) {
            if( _st.isNUE( payload ) ) {
                return;
            }

            localStorage.setItem('m2m-admin-token', payload);

            let decoded = jwt.decodeJwt( payload );
            commit('setTokenExp', decoded.exp);
        },
        signOut({ commit }) {
            localStorage.removeItem('m2m-admin-token');
            store.commit('orders/setOrders', []);
            store.commit('orders/setNeedRefresh', true);
            commit('setUserLongName', '');
            commit('setUserLastLogin', '');
            commit('setUserPrivileges', {});
            router.push('/admin');
        },
        async loadTokenLocalStorage({ getters, commit, dispatch }) {
            if( !getters.isLoggedIn() )
                return;

            let token = getters.userToken();
            
            if( token == undefined || token == null || token == '' ) {
                return;
            }

            let decoded = jwt.decodeJwt( token );
            commit('setUserLongName', decoded.nme);
            commit('setUserLastLogin', decoded.llg);
            commit('setTokenExp', decoded.exp);
            await dispatch('getUserPrivileges');

        },
    },
    getters: {
        appState        : (state) => state.appState,
        pageTitle       : (state) => state.pageTitle,
        orderToView     : (state) => state.orderToView,
        userLongName    : (state) => state.userLongName,
        userLastLogin   : (state) => state.userLastLogin,
        userPrivileges  : (state) => state.userPrivileges,

        // lo puse como una función para q no vuex no hiciera cache del token.
        // pasaba q un usuario podia hacer request con el token del otro pq el token
        // había sido cached.
        userToken       : () => () => localStorage.getItem('m2m-admin-token'),


        isLoggedIn      : (state) => () => !_st.isNUE( localStorage.getItem('m2m-admin-token') )
                                    && localStorage.getItem('m2m-admin-token').length > 20
                                    && Date.now() < state.tokenExp * 1000,
        can             : (getters) => (privilege) => {
            if( _st.isNUE( getters.userPrivileges ) )
                return false;
            
            return getters.userPrivileges.some(p => p.privilege == privilege);
        },
        isReadOnly      : (getters) => (privilege) => {
            if( _st.isNUE( getters.userPrivileges ) )
                return false;

            if( !getters.userPrivileges.some(p => p.privilege == privilege) )
                return false;
            
            let priv = getters.userPrivileges.find(p => p.privilege == privilege);
            if( _st.isNUE( priv.meta ) ) {
                return false;
            }

            return priv.meta.split(';')[2] == 'readonly' ? true : false;
        },
        privilegeMeta   : (getters) => (privilege) => {
            if( _st.isNUE( getters.userPrivileges ) )
                return {};

            if( !getters.userPrivileges.some(p => p.privilege == privilege) )
                return {};
            
            let priv = getters.userPrivileges.find(p => p.privilege == privilege);
            
            if( _st.isNUE( priv.meta ) ) {
                return {
                    stores: '',
                    status: '',
                    readOnly: false
                };
            }

            return {
                stores: priv.meta.split(';')[0].split(',') === priv.meta.split(';')[0] ? [] : priv.meta.split(';')[0].split(','),
                status: priv.meta.split(';')[1].split(',') === priv.meta.split(';')[1] ? [] : priv.meta.split(';')[1].split(','),
                readOnly: priv.meta.split(';')[2] == 'readonly'
            };
        }
    }
}