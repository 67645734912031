import store from '@/store';
import axios from 'axios';

export class API {
    constructor( fromRental = false ) {
        this.fromRental         = fromRental;
        this.baseURL            = fromRental
                                    ? process.env.NODE_ENV === 'production' ? 'https://api.l5a.com/lfa/v1/index.php/' : 'http://192.168.69.165/api/lfa/v1/index.php/'
                                    : process.env.NODE_ENV === 'production' ? 'https://api.l5a.com/dtm/v1/index.php/' : 'http://192.168.69.165/api/dtm/v1/index.php/';
        this.withCredentials    = false;
        this.headers            = this._getHeaders();
        this.http               = this._getAxios();
        this.controller         = this._getController();
    }

    async get( endpoint ) {
        try {
            let res = await this.http.get( endpoint, {
                signal: this.controller.signal
            });
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async post( endpoint, data = null, config = null ) {
        try {
            let res = await this.http.post( endpoint, data, config );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async put( endpoint, data = null ) {
        try {
            let res = await this.http.put( endpoint, data );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    cancelRequest(controller = null) {
        if( controller != null ) {
            controller.abort();
        }
    }

    cancel() {
        this.controller.abort();
    }

    _getHeaders() {
        let auth =  this.fromRental 
                        ? '8046b3b5-4e93-11ea-9119-12761e074d50'    // rental app api key
                        : '0c829d1c-db8d-11e8-ae2e-deadbe115600';   // dtm api key
        if( store.getters['admin/isLoggedIn']() ) {
            auth += `,Bearer ${store.getters['admin/userToken']()}` 
        }

        return {
            Authorization: auth
        };
    }

    _getAxios() {
        let http = axios.create({
            baseURL         : this.baseURL,
            withCredentials : this.withCredentials,
            headers         : this.headers,
            validateStatus(status) {
                if (status >= 200 && status < 300 && status !== 204) {
                    return true;
                } 
                else if( status == 403 ) {
                    return true;
                }
                
                return false;
            }
        });

        http.interceptors.response.use( function(response) {
            if( response.status === 401 && response.data.signout === true ) {
                store.dispatch('admin/signOut');
            }

            return response;
        }, function(error) {
            if (error.response != undefined && error.response.status === 401 && error.response.data.signout === true) {
                store.dispatch('admin/signOut');
            }

            return Promise.reject(error);
        });

        return http;
    }

    _getController() {
        return new AbortController();
    }
}