<template>
    <div>
    <v-speed-dial v-model="fab" fixed :top="this.settings.top" :bottom="this.settings.bottom" :right="this.settings.right" :left="this.settings.left" :direction="this.settings.direction"
        :open-on-hover="this.settings.openOnHover" :transition="this.settings.transition" style="margin-left: 70px;">
        <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-btn dark small color="green" @click="gotoCoat()">
            Coat
        </v-btn>
        <v-btn dark small color="indigo" @click="gotoPant()">
            Pant
        </v-btn>
        <v-btn dark small color="red" @click="gotoShirt()">
            Shirt
        </v-btn>
        <v-btn dark small color="blue" @click="gotoVest()">
            Vest
        </v-btn>
        <v-btn dark small color="blue-grey" @click="gotoAccessory()">
            Accessory
        </v-btn>
    </v-speed-dial>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        settings: { type: Object, default: () => {} }
    },
    data: () => ({
        fab: false
    }),
    methods: {
        ...mapActions({
            updateStore: 'updateStore'
        }),
        gotoCoat() {
            this.updateStore({ prop: 'typeId', payload: '1' });
            this.updateStore({ prop: 'productId', payload: 7644 });
            this.updateStore({ prop: 'productName', payload: 'Coat' });
            this.updateStore({ prop: 'price', payload: 550.00 });
            this.updateStore({ prop: 'qty', payload: 1 });
            this.$router.push('/designer');
        },
        gotoPant() {
            this.updateStore({ prop: 'typeId', payload: '2' });
            this.updateStore({ prop: 'productId', payload: 7717 });
            this.updateStore({ prop: 'productName', payload: 'Pant' });
            this.updateStore({ prop: 'price', payload: 249.00 });
            this.updateStore({ prop: 'qty', payload: 1 });
            this.$router.push('/designer');
        },
        gotoShirt() {
            this.updateStore({ prop: 'typeId', payload: '3' });
            this.updateStore({ prop: 'productId', payload: 7701 });
            this.updateStore({ prop: 'productName', payload: 'Shirt' });
            this.updateStore({ prop: 'price', payload: 169.00 });
            this.updateStore({ prop: 'qty', payload: 1 });
            this.$router.push('/designer');
        },
        gotoVest() {
            this.updateStore({ prop: 'typeId', payload: '4' });
            this.updateStore({ prop: 'productId', payload: 7810 });
            this.updateStore({ prop: 'productName', payload: 'Vest' });
            this.updateStore({ prop: 'price', payload: 229.00 });
            this.updateStore({ prop: 'qty', payload: 1 });
            this.$router.push('/designer');
        },
        gotoAccessory() {
            this.updateStore({ prop: 'typeId', payload: '5' });
            this.updateStore({ prop: 'productId', payload: 8000 });
            this.updateStore({ prop: 'productName', payload: 'Accessory' });
            this.updateStore({ prop: 'price', payload: 100.00 });
            this.updateStore({ prop: 'qty', payload: 1 });
            this.$router.push('/designer');
        },
    }
}
</script>

<style lang="scss" scoped>

</style>