<template>
    <v-breadcrumbs :items="items" class="body-1">
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    computed: {
        items() {
            if (typeof this.$route.meta.breadcrumbs === "function") {
                return this.$route.meta.breadcrumbs.call(this, this.$route);
            }

            return this.$route.meta.breadcrumbs
        }
    }
}
</script>

<style lang="scss" scoped>

</style>