import { serverBus } from '@/main';
import { API } from '@/inc/api';

export const orders = {
    namespaced: true,
    state: {
        orders      : [],
        needRefresh : true,
    },
    mutations: {
        setOrders( state, payload ) {
            state.orders = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async load({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/orderlist');

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setOrders', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async loadSingle(_, orderId) {
            try {
                let api = new API();
                let res = await api.get(`/admin/orderdetails/${orderId}`);

                if( res.data.status !== true ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOrderStatus(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/orderstatus', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOrderNote(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/ordernote', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOrderShipping(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/ordershipping', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOrderOther(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/orderother', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOrderRush(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/orderrush', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setMeasurements(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/ordermeasurements', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setCustomerInfo(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/ordercustomerinfo', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setCustomerImages(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/ordercustomerimages', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setOtherImages(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/orderotherimages', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setItemData(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/itemdata', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async setAppointmentDate({ commit }, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/appointmentdate', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async printReceipt(_, { orderId, contract, lang }) {
            try {
                let api = new API();
                let res = null;
                
                if( contract == '1' ) {
                    res = await api.post(`/download-contract`, { orderId }, {  responseType: 'blob' });

                    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    document.getElementById('printFrame').src = url;
                    setTimeout( () => window.frames['printFrame'].print(), 100 );
                }
                else {
                    res = await api.http.get(`/orderreceipt/${orderId}/${lang}`, { 
                        responseType: 'blob'
                    });

                    const file = new Blob([res.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL( file );
                    document.getElementById('printFrame').src = url;

                    // this is a workaround for chrome that 
                    // doesn't resprect the PDF automatic printing
                    let isFirefox = typeof InstallTrigger !== 'undefined';
                    if( !isFirefox )
                        setTimeout( () => window.frames['printFrame'].print(), 100 );
                }

                // if( res.data.status !== true ) {
                //     serverBus.$emit('alert', { 
                //         text    : res.data.message, 
                //         color   : res.data.error ? 'error' : 'warning'
                //     });
                //     return Promise.reject( false );
                // }

                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async printFactoryOrder(_, { orderId, comment }) {
            try {
                let api = new API();
                let res = await api.post('/forder', {
                    orderId,
                    comment
                }, { responseType: 'blob' });

                // if( res.data.status !== true ) {
                //     serverBus.$emit('alert', { 
                //         text    : res.data.message, 
                //         color   : res.data.error ? 'error' : 'warning'
                //     });
                //     return Promise.reject( false );
                // }

                const file = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL( file );
                document.getElementById('printFrame').src = url;

                // this is a workaround for chrome that 
                // doesn't resprect the PDF automatic printing
                let isFirefox = typeof InstallTrigger !== 'undefined';
                if( !isFirefox )
                    setTimeout( () => window.frames['printFrame'].print(), 100 );

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async sendCustomerEmail(_, data) {
            try {
                let api = new API();
                let res = await api.post('/admin/customeremail', data);

                if( !res.data.status ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                return Promise.resolve( res.data.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async sendContractByEmail(_, { orderId, email }) {
            try {
                let api = new API();
                let res = await api.post('/admin/resend-contract-email', { orderId, email });

                if( res.data.status !== true ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.resolve( false );
                }

                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async sendContractByPhone(_, { orderId, phone }) {
            try {
                let api = new API();
                let res = await api.post('/admin/resend-contract-phone', { orderId, phone });

                if( res.data.status !== true ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.resolve( false );
                }

                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async syncWithPOS(_, { orderId }) {
            try {
                let api = new API();
                let res = await api.post('/admin/sync-pos', { orderId });

                if( res.data.status !== true ) {
                    serverBus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.resolve( false );
                }

                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        setNeedRefresh({ commit }, payload) {
            commit('setNeedRefresh', payload);
        }
    },
    getters: {
        orders      : (state) => state.orders,
        needRefresh : (state) => state.needRefresh
    }
}