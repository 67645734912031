<template>
    <div class="text-center">
        <v-dialog v-model="local" width="500" persistent>
            <v-card>
                <v-card-title>
                    <p class="title">Select Store</p>
                </v-card-title>
                <v-card-text>
                    <v-alert :value="showAlert" color="error" type="warning">{{ this.alertMessage }}</v-alert>
                    <v-select :items="stores" v-model="selectValue" label="Store"></v-select>
                    <v-text-field label="Password" v-model="storePassword" type="password" @keydown.enter="loginStore"></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="loginStore">
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        show    : { type: Boolean, default: false }
    },
    data() {
        return {
            local           : this.show,
            stores          : [
                { text: 'San Juan', value: 1 },
                { text: '65 de Infanteria', value: 12 },
                { text: 'Arecibo', value: 11 },
                { text: 'Bayamón', value: 3 },
                { text: 'Caguas', value: 2 },
                { text: 'Cataño', value: 13 },
                { text: 'Mayaguez', value: 4 },
                { text: 'Ponce', value: 5 },
                { text: 'Santo Domingo', value: 6 },
                { text: 'Winter Park', value: 9 },
            ],
            selectValue     : '',
            storePassword   : '',
            showAlert       : false,
            alertMessage    : '',
        }
    },
    computed: {
        ...mapGetters({
            store       : 'store',
        }),
        form() {
            return {
                storeId: this.selectValue,
                password: this.storePassword
            }
        },
    },
    watch: {
        show(v) {
            this.local = v;
        }
    },
    methods: {
        ...mapActions({
            updateStore     : 'updateStore',
            load            : 'loadFromLocalStorage',
        }),
        async loginStore() {
            this.showAlert = false;
            this.alertMessage = '';
            
            if( this.selectValue != '' && this.storePassword != '') {
                try {
                    let api = new API();

                    this.processing = true;
                    let res = await api.post( '/valstr', this.form );

                    if( res.data.status !== true ) {
                        this.alertMessage = res.data.message;
                        this.showAlert = true;
                        return;
                    }
                    
                    // If everything is ok, go to thankyou page
                    var obj = { value: this.selectValue, timeStamp: new Date().getTime() }
                    localStorage.setItem("m2m-selected-store", JSON.stringify(obj));
                    this.updateStore({
                        store       : obj.value,
                        storeDialog : false
                    });

                    this.storePassword = '';
                    this.selectValue = '';
                    this.$emit('loggedin');
                    return;
                }
                catch( error ) {
                    console.log(error);
                }
                finally {
                    this.processing = false;
                }
            }
            else
                alert('You must select a store and enter the password before login in.');
        },
    }
}
</script>

<style lang="scss" scoped>

</style>